import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import SectionCards from '../components/cards/SectionCards';
import styles from './Catalogue.module.css';
import top_yellow from './assets/assets.new-home/top-yellow-2.png';
import classesService from '../services/classes.service';
import NoWorshopSearch from '../components/error/NoWorshopSearch';

const strings = {
  title: 'Encuentra el taller perfecto para tí',
  label_search: 'Buscar un taller',
};

const dropwdown = [
  { title: 'Disponibles', value: 'available' },
  { title: 'Finalizados', value: 'unavailable' },
  { title: 'Todos', value: 'all' },
];

function Catalogue(props) {
  let { state } = useLocation();

  const [catalogueFilter, setFilter] = useState(state.filter);
  const [catalogueQuery, setWord] = useState('');
  const [data, setData] = useState(state.data);

  const search = (q, f) => {
    setData(undefined); //cargando
    const text = q.toString();
    const filt = f;
    classesService
      .searchWorshop(text, filt)
      .then((resp) => {
        const workshops = resp.data.data;
        setData(workshops); // resultado talleres
        if (workshops === null) {
          setData([]); //no hay talleres
        }
      })
      .catch((e) => {
        setData([]); //no hay talleres
      });
  };

  const handleEnter = (e) => {
    if (e.key === 'Enter') {
      const value = e.target.value;
      setWord(value);
      search(value, catalogueFilter);
    }
  };

  const handleSubmit = (e) => {
    const input_value = document.querySelector('input');
    const value = input_value.value;
    setWord(value);
    search(value, catalogueFilter);
  };

  function dropdown_selected(e) {
    const value = e.target.value;
    setFilter(value);
    search(catalogueQuery, value);
  }

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className={styles.body_catalogue}>
      <>
        <div className={`py-5 ${styles.container_header} mb-5`}>
          <div>
            <div className={`text-center mb-5`}>
              <section>
                <div class='mx-4'>
                  <h1 class='fw-medium'>{strings.title}</h1>
                </div>
              </section>
            </div>

            <div
              className={`mx-auto col-lg-6 col-md-8 col-sm-8 col-8 ${styles.search_bar}`}
            >
              <div class={styles.search_bar} role='search'>
                <input
                  class={`fw-light px-4 ${styles.search_bar_input}`}
                  type='search'
                  name='search_input'
                  placeholder={strings.label_search}
                  onKeyDown={handleEnter}
                  value={catalogueQuery}
                  onChange={(event) => setWord(event.target.value)}
                />
                <button
                  class={`${styles.search_bar_icon}`}
                  onClick={handleSubmit}
                >
                  <i class='fas fa-search'></i>
                </button>
              </div>
            </div>

            <div
              className={`mx-auto mt-4  col-lg-8 col-md-8 d-flex justify-content-center `}
            >
              <div class='dropdown'>
                <button
                  class={`btn dropdown-toggle ${styles.dropdown}`}
                  type='button'
                  data-bs-toggle='dropdown'
                  aria-expanded='false'
                >
                  {catalogueFilter === 'available'
                    ? 'Disponibles'
                    : catalogueFilter === 'unavailable'
                    ? 'Finalizados'
                    : 'Todos'}
                </button>
                <ul class='dropdown-menu' onClick={dropdown_selected}>
                  {dropwdown.map((item, index) => (
                    <li key={index}>
                      <button
                        class={`dropdown-item ${styles.dropdown_item}`}
                        value={item.value}
                      >
                        {item.title}
                      </button>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>

        <SectionCards n={null} typeclass={props.type} data={data}>
          <NoWorshopSearch />
        </SectionCards>

        <img
          className={styles.bottom_yellow}
          src={top_yellow}
          alt='adorno bottom yellow'
        />
      </>
    </div>
  );
}

export default Catalogue;
