import React from 'react';
import CardCustom from '../../components/cards/Card';
import SkeletonCard from './SkeletonCard';

const SectionCards = ({data, n, children}) => {
  // const [data, setData] = useState(props.data);
  //undefined -> cargando
  //null -> carga talleres
  //[] -> NO hay talleres
  return (
    <>
      <div className='mx-5 '>
        {data === undefined || data === null ? (
          <div className='row row-cols-xl-4  row-cols-md-2 row-cols-1 g-4'>
            {/* // {/* <CircularProgress color='secondary' />  */}
            {Array.from(Array(!n ? 20 : n).keys()).map(
              (clase, index) => {
                return <SkeletonCard key={index} />;
              }
            )}
          </div>
        ) : data.length === 0 ? (
          <div className='row d-flex justify-content-center pb-2 fw-light'>
            <div className='m-4 mx-auto text-center' style={{ color: 'black' }}>
              {children}
            </div>
          </div>
        ) : (
          <div className='row row-cols-xl-4  row-cols-md-2 row-cols-1 g-4'>
            {data.slice(0, !n ? 20 : n).map((clase, index) => { 
              return <CardCustom clase={clase} key={index} />;
            })}
          </div>
        )}
      </div>
    </>
  );
};

export default SectionCards;
