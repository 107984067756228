import React from 'react';

const error_message_title = 'Error página no encontrada!';
const error_message_subtitle = 'Pruebe con otra dirección o recargue la página.';

function ErrorPage() {
  return (
    <div class='text-center'>
      <div className='fs-4'>{error_message_title}</div>
      <p class='lead fs-6'>{error_message_subtitle}</p>
      <p class='lead'>
        <a href='/' class='btn btn-lg btn-light fw-bold border-white bg-white'>
          Home
        </a>
      </p>
    </div>
  );
}

export default ErrorPage;
