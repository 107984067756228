import React from 'react';
// import './Card.css';
import styles from './Cards.module.css';
import moment from 'moment/moment';
import { Link } from 'react-router-dom';

moment.locale('es_CL', {
  months:
    'Enero_Febrero_Marzo_Abril_Mayo_Junio_Julio_Agosto_Septiembre_Octubre_Noviembre_Diciembre'.split(
      '_'
    ),
  monthsShort:
    'Enero._Feb._Mar._Abr._May._Jun._Jul._Ago._Sept._Oct._Nov._Dec.'.split('_'),
  weekdays: 'Domingo_Lunes_Martes_Miercoles_Jueves_Viernes_Sabado'.split('_'),
  weekdaysShort: 'Dom._Lun._Mar._Mier._Jue._Vier._Sab.'.split('_'),
  weekdaysMin: 'Do_Lu_Ma_Mi_Ju_Vi_Sa'.split('_'),
});

const CardCustom = ({ clase }) => {
  var enlace = ""
  console.log(clase)
  if (clase.is_event) {
    enlace = "/event/" + clase.event_id
  } else {
    enlace = "/clase/" + clase.id
  }

  const sessionValue = (value) => {
    if (value === 0) return 'Gratis';
    var formatter = new Intl.NumberFormat('es-CL', {
      style: 'currency',
      currency: 'CLP',
    });
    value = formatter.format(value);
    return value;
  };

  return (
    <div class='col'>
      <Link
        style={{ color: 'rgba(1, 1, 1, 0)', padding: '0', margin: '0' }}
        to={enlace}
      >
        <div class={`card h-100 shadow-sm  ${styles.card_custom}`} >
          <img
            width='100%'
            height='240'
            src={
              clase?.multimediaClass === undefined
                ? ''
                : clase?.multimediaClass[0].url
            }
            class="card-img-top"
            style={{ objectFit: 'cover' }}
            alt={clase?.multimediaClass[0].description}
          />
          <div className={`card-body`}>
            <p class={`card-text fw-semibold text-truncate ${styles.parraf}`}>{clase.title}</p>
            <p
              className={`card-text fw-light d-inline-block text-truncate ${styles.description_text} ${styles.parraf}`}
            >
              {clase.description}
            </p>
            <p class={`card-text fw-medium ${styles.parraf}`}>
              Valor: {sessionValue(clase.price)}
            </p>
          </div>
        </div>
      </Link>
    </div>
  );
};

export default CardCustom;