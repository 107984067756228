import { api, fileApi } from '../helpers';

const basePath = 'api';

async function getAll(filter) {
  if (filter === 'unavailable')
    return await api.get(`${basePath}/clases`, {
      params: { available: false },
    });
  if (filter === 'available')
    return await api.get(`${basePath}/clases`, { params: { available: true } });
  else return await api.get(`${basePath}/clases`);
}

async function getDetail(classId) {
  return await api.get(`${basePath}/clases/${classId}`);
}

async function searchWorshop(word, filter) {
  if (filter !== null) {
    if (filter === 'unavailable')
      return await api.get(`${basePath}/clases`, {
        params: { search: word, available: false },
      });
    if (filter === 'available')
      return await api.get(`${basePath}/clases`, {
        params: { search: word, available: true },
      });
    // if (filter === 'all' && word !== '')
    //   return await api.get(`${basePath}/clases`, {
    //     params: { search: word },
    //   });
    if (filter === 'all' && word === '')
      return await api.get(`${basePath}/clases`);
  }
  return await api.get(`${basePath}/clases`, { params: { search: word } });
}

async function postInscribirClase(payload) {
  return await api.post(`${basePath}/inscribir`, payload);
}

async function buyClassWithElectronicTransfer(payload) {
  return await api.post(`${basePath}/inscribir/transfer`, payload);
}

async function uploadFileElectronicTransfer(payload) {
  return await fileApi.post(`${basePath}/file/transfer`, payload);
}

async function getcheckoutDetailClass(classId) {
  return await api.get(`${basePath}/clases/checkout/${classId}`);
}

async function postNotEnableStudentInscriptions(payload) {
  return await api.post(`${basePath}/interested/student`, payload);
}

async function postNotEnableCompanyInscriptions(payload) {
  return await api.post(`${basePath}/interested/company`, payload);
}

async function postInterestedWorkshops(payload) {
  return await api.post(`${basePath}/interested`, payload);
}

async function postMercadoPagoPreference(payload) {
  return await api.post(`${basePath}/inscribir/mercadopago`, payload);
}

async function postDiscount(payload) {
  return await api.post(`${basePath}/clases/checkout/discount`, payload);
}

async function getEventDetail(classId) {
  return await api.get(`${basePath}/event/${classId}`);
}

async function postEventInscription(payload) {
  return await api.post(`${basePath}/event/inscription`, payload);
}

const classesService = {
  getAll,
  getDetail,
  postInscribirClase,
  searchWorshop,
  buyClassWithElectronicTransfer,
  uploadFileElectronicTransfer,
  getcheckoutDetailClass,
  postInterestedWorkshops,
  postNotEnableStudentInscriptions,
  postNotEnableCompanyInscriptions,
  postMercadoPagoPreference,
  postDiscount,
  getEventDetail,
  postEventInscription,
};

export default classesService;
