import React from 'react';

function Success() {
    return (
      <div class='text-center'>
        <div className='fs-4'>¡Pago aceptado con éxito!</div>
        <p class='lead fs-6'>Nos comunicaremos a la brevedad mediante correo confirmando que hemos recibido tu pago</p>
        <p class='lead'>
          <a href='/' class='btn btn-lg btn-light fw-bold border-white bg-white'>
            Home
          </a>
        </p>
      </div>
    );
  }
export default Success;