import { React, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import classesService from '../services/classes.service';
import DetailClassOptional from '../components/detail-class/DetailClassOptional';
import { track } from '@amplitude/analytics-browser';

function Event() {
  let { id } = useParams();
  const [detail, setDetail] = useState(null);
  let eventProperties = {
    'eventId': id
  }
  track('visita-clase', eventProperties);

  useEffect(() => {
    // fetch classes
    classesService
      .getEventDetail(id)
      .then((resp) => {
        var _detail = resp.data.data;
        setDetail(_detail);
      })
  }, [id]);

  return (
    <div>

      <DetailClassOptional detail={detail} id={id} />
    </div>
  );
}

export default Event;
