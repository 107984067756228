import React from 'react';

function Pending() {
    return (
      <div class='text-center'>
        <div className='fs-4'>¡Pago pendiente!</div>
        <p class='lead fs-6'>Revisaremos el estado de tu pago y nos comunicaremos contigo.</p>
        <p class='lead'>
          <a href='/' class='btn btn-lg btn-light fw-bold border-white bg-white'>
            Home
          </a>
        </p>
      </div>
    );
  }
export default Pending;