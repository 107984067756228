import React, { useState, useEffect } from 'react';
import classesService from '../services/classes.service';
import { track } from '@amplitude/analytics-browser';
import CustomSection from '../components/section/Section';
// import { Container } from '@mui/system';

import styles from './NewHome.module.css';
import SectionCards from '../components/cards/SectionCards';

import bottom_yellow from './assets/assets.new-home/bottom-yellow-2.png';
import top_yellow from './assets/assets.new-home/top-yellow-2.png';
import top_white from './assets/assets.new-home/top-white-2.png';
import RequestNewClass from '../components/requestNewClass/RequestNewClass';
import { Link } from 'react-router-dom';
import NoWorshop from '../components/error/NoWorshop';

function NewHome() {
  track('visita');
  const [available, setAvailable] = useState(undefined);
  const [unavailable, setUnavailable] = useState(undefined);

  function fetchData() {
    classesService
      .getAll('available')
      .then((resp) => {
        setAvailable(resp.data.data);

        if (resp.data.data === null) {
          setAvailable([]); //no hay talleres
        }
      })
      .catch((e) => {
        setAvailable([]); //no hay talleres
      });

    // fetching unavailable
    classesService
      .getAll('unavailable')
      .then((resp) => {
        setUnavailable(resp.data.data);
        if (resp.data.data === null) {
          setUnavailable([]); //no hay talleres
        }
      })
      .catch((e) => {
        setUnavailable([]); //no hay talleres
      });
  }

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      <div className={`col ${styles.first_section}`}>
        <img
          className={styles.top_white}
          src={top_white}
          alt='adorno top white'
        />
        <div className={`${styles.text_header}`}>
          <section class={`py-2 text-center container`}>
            <div class='row py-lg-5'>
              <div class='col-lg-12 col-md-12 mx-auto'>
                <h1 class={`fw-bold  ${styles.text_apprende}`}>Apprende</h1>
                <p class={`lead ${styles.text_lead}`}>
                  La plataforma para compartir tus conocimientos y aprender de
                  los demás
                </p>
              </div>
            </div>
          </section>
        </div>
      </div>
      <div className={styles.disponibles}>
        <div className={`m-0  ${styles.white_section}`}>
          <CustomSection title={'Talleres recién aterrizados'} />
          <SectionCards n={8} typeclass={'available'} data={available}>
            <NoWorshop category={true} />
          </SectionCards>
          <Link
            style={{ color: 'rgba(1, 1, 1, 0)', padding: '0', margin: '0' }}
            to={'/catalogo'}
            state={{ filter: 'available', data: available }}
            preventScrollReset={false}
          >
            <div className='d-grid col-lg-3 col-md-5 col-sm-6 col-8 mx-auto px-5 py-4'>
              <button
                type='button'
                class={`btn btn-primary btn-sm ${styles.button_white}`}
              >
                Ver todos los talleres
              </button>
            </div>
          </Link>
        </div>
      </div>
      <div className={styles.nodisponibles}>
        <img
          className={styles.top_yellow}
          src={top_yellow}
          alt='adorno top yellow'
        />
        <div className={`m-0 ${styles.yellow_section}`}>
          <CustomSection title={'Talleres Finalizados'} />
          <SectionCards n={8} typeclass={'unavailable'} data={unavailable}>
            <NoWorshop category={false} />
          </SectionCards>
          <Link
            style={{
              color: 'white',
              padding: '0',
              margin: '0',
              textDecoration: 'none',
            }}
            to={'/catalogo'}
            state={{ filter: 'unavailable', data: unavailable }}
            preventScrollReset={false}
          >
            <div className='d-grid col-lg-1 col-md-1 col-3 mx-auto py-4'>
              <button
                type='button'
                class={`btn btn-primary btn-sm ${styles.button_yellow}`}
              >
                Ver más
              </button>
            </div>
          </Link>
        </div>

        <img
          className={styles.bottom_yellow}
          src={bottom_yellow}
          alt='adorno bottom yellow'
        />
      </div>

      <RequestNewClass
        title={'Solicitar Taller'}
        classService={classesService}
      />
    </>
  );
}

export default NewHome;
