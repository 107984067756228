import React from 'react';

function PrivacyPolicy() {
  return (
    <div className='container-fluid mt-5' style={{maxWidth: "800px"}}>
      <header>
        <h1>Política de Privacidad de Apprende</h1>
      </header>
      <section className='mt-3'>
        <h2>Introducción</h2>
        <p>En Apprende, accesible desde https://www.apprendechile.com, una de nuestras principales prioridades es la privacidad de nuestros visitantes. Este documento de Política de Privacidad informa sobre los tipos de información que recopila Apprende y cómo se utiliza.</p>
      </section>
      <section>
        <h2>Información que recopilamos</h2>
        <p>La información personal que se pide que proporciones, y las razones por las que se te pide que la proporciones, te será aclarada en el momento en que te pidamos que proporciones tu información personal.</p>
        <p>Cuando te registres para una Clase, podríamos pedir tu información de contacto, incluyendo elementos como nombre, edad, institucion educativa a la que perteneces, dirección de email, y número de teléfono.</p>
      </section>
      <section>
        <h2>Cómo usamos tu información</h2>
        <p>Usamos la información que recopilamos de varias maneras, incluyendo para:</p>
        <ul>
          <li>Proveer, operar y mantener nuestra web</li>
          <li>Mejorar, personalizar y expandir nuestra web</li>
          <li>Entender y analizar cómo usas nuestra web</li>
          <li>Desarrollar nuevos productos, servicios, características y funcionalidades</li>
        </ul>
      </section>
      <section>
        <h2>Compartir tu información personal</h2>
        <p>No vendemos, comercializamos, o de otra manera transferimos a partes externas tu información personal, salvo que sean organizadores de las instancias educativas a las que hayas participado, y solo se le entregará aquella a la que te hayas inscrito.</p>
      </section>
      <section>
        <h2>Seguridad de los datos</h2>
        <p>La seguridad de tus datos personales es importante para nosotros, pero recuerda que ningún método de transmisión por Internet o método de almacenamiento electrónico es 100% seguro. Mientras nos esforzamos por usar medios comercialmente aceptables para proteger tu información personal, no podemos garantizar su seguridad absoluta.</p>
      </section>
      <section>
        <h2>Cambios a esta política de privacidad</h2>
        <p>Podemos actualizar nuestra Política de Privacidad de vez en cuando. Te notificaremos de cualquier cambio publicando la nueva Política de Privacidad en esta página.</p>
        <p>Te aconsejamos revisar esta Política de Privacidad periódicamente para cualquier cambio. Los cambios a esta Política de Privacidad son efectivos cuando son publicados en esta página.</p>
      </section>
      <footer>
        <p>Si tienes alguna pregunta sobre esta Política de Privacidad, puedes contactarnos.</p>
      </footer>
    </div>
  );
}

export default PrivacyPolicy;
