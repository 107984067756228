import moment from 'moment/moment';

export const sessionValue = (value) => {
  if (value === '0') return 'Gratis';
  var formatter = new Intl.NumberFormat('es-CL', {
    style: 'currency',
    currency: 'CLP',
  });
  value = formatter.format(value);
  return value;
};
export const dateValue = (date) => {
  // 2022-10-02 21:23:42
  if (date === undefined) return '';
  let date_splitted = date.split(' ')[0];
  const fecha = new Date(date_splitted).toISOString();
  let date_string = moment(fecha).add(1, 'days').format('DD [de] MMMM');
  return date_string;
};
export const hourValue = (date) => {
  // 2022-10-02 21:23:42
  if (date === undefined) return '';

  let date_splitted = date.split(' ')[1];
  date_splitted =
    date_splitted.split(':')[0] + ':' + date_splitted.split(':')[1];
  return date_splitted;
};
