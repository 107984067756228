import axios from 'axios';

const endpoints = {
    // development: 'https://apprennde.herokuapp.com',
    development: process.env.REACT_APP_BACKEND_URL,
};

export const api = axios.create({
    baseURL: endpoints['development'],
    timeout: 20000,
    // withCredentials: true
    headers: {
        post: {        // can be common or any other method
            'Content-Type' : 'application/json;charset=utf-8',
        }
    },
});

export const fileApi = axios.create({
    baseURL: endpoints['development'],
    timeout: 20000,
    // withCredentials: true
    headers: {
        post: {        // can be common or any other method
            'Content-Type' : 'multipart/form-data',
        }
    },
});

