const CustomSection = ({ title }) => {
  return (
    <>
      <section class='text-center container'>
        <div class='row pb-3'>
          <div class='col-lg-6 col-md-8 mx-auto'>
            <h1 class='fw-bold fs-3'>{title}</h1>
          </div>
        </div>
      </section>
    </>
  );
};

export default CustomSection;
