import { React } from 'react';
import styles from './DetailClassOptional.module.css';
import { dateValue, hourValue, sessionValue } from '../../helpers';

function AboutWorkshop(props) {
  let countSessions = 0;
  return (
    <>
      <div className={`${styles.detail_subtitle_class}`}>
        Acerca del taller
      </div>

      <ul className={`${styles.detail_content_class} ${styles.ul_class}`}
      >
        <li className={styles.li_class}>
          <b>Modalidad:</b> {props.details?.modalidad}
        </li>
        <li className={styles.li_class}>
          <b>Precio:</b> {sessionValue(props.details?.precio)}
        </li>
        <li className={styles.li_class}>
          <b>Lugar:</b> {props.details?.ubicacion}
        </li>
        <li className={styles.li_class}>
          <b>Vacantes:</b> {props.details?.vacantes}
        </li>
        {props.details?.dates?.length > 1 ? <li className={styles.li_class}><b>Sesiones del taller:</b> {props.details?.dates.length} </li> : <></>}
        {props.details?.dates?.map((content) => {
          if (props.details?.dates.length > 1) {
            countSessions++
            return (
              <>
                <li className={styles.li_class}>
                  <b>Sesión {countSessions}:</b>
                </li>
                <ul className={`${styles.ul_class}`}
                >
                  <li className={styles.li_class}>
                    <b>Fecha:</b> {dateValue(content.DateClass)}
                  </li>
                  <li className={styles.li_class}>
                    <b>Hora:</b> {hourValue(content.DateClass)}
                  </li>
                  {content.Duration !== "" ? <li className={styles.li_class}><b>Duración:</b> {content.Duration} </li> : <></>}
                </ul>
              </>
            );
          } else {
            return (
              <>
                <li className={styles.li_class}>
                  <b>Fecha:</b> {dateValue(content.DateClass)}
                </li>
                <li className={styles.li_class}>
                  <b>Hora:</b> {hourValue(content.DateClass)}
                </li>
                {content.Duration !== "" ? <li className={styles.li_class}><b>Duración:</b> {content.Duration} </li> : <></>}
              </>
            );
          }
        })}
      </ul>
    </>
  );
}
export default AboutWorkshop;
