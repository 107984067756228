import { React, useState } from 'react';
import './Inscribirse.css';
import Swal from 'sweetalert2';
import classesService from '../../services/classes.service';
import styles from './CustomButton.module.css';

function NotEnableStudentInscriptions(props) {
  const id = props.id;
  const [userName, setUserName] = useState();
  const [userUbication, setUbication] = useState();
  const [userMobile, setUserMobile] = useState();

  const [userEmail, setUserEmail] = useState({ value: '', valid: undefined });
  const [userEmailRepeat, setUserEmailRepeat] = useState({
    value: '',
    valid: undefined,
  });

  const [isValidEmailRepeat, setIsValidEmailRepeat] = useState(undefined);

  const handleSubmit = async (e) => {
    e.preventDefault();
    var payload = {
      classId: parseInt(id),
      userName: userName,
      mobile: userMobile,
      city: userUbication,
      email: userEmail.value,
    };
    classesService
      .postNotEnableStudentInscriptions(payload)
      .then(function (res) {
        Swal.fire({
          confirmButtonColor: '#6F119B',
          text: 'Gracias por tu interés, te contactaremos si se vuelve a dictar esta clase.',
          icon: 'success',
          showConfirmButton: true,
          confirmButtonText: 'Entendido',
        }).then(function (res) {
          Swal.close();
          document.getElementById('topCloseButtonModal').click();
        });
        return res;
      })
      .catch(function (res) {
        Swal.fire({
          showConfirmButton: true,
          confirmButtonColor: '#6F119B',
          // cancelButtonColor: '#6F119B',
          title: 'Error!',
          text: 'Tuvimos un error con nuestros sistemas, puedes comunicarte a través de nuestras redes sociales para solicitar ayuda.',
          icon: 'error',
          confirmButtonText: 'Entendido',
        }).then(function (res) {
          Swal.close();
          document.getElementById('topCloseButtonModal').click();
        });
      });
  };

  const validEmailRegex = RegExp(
    /^((([^<>()[\].,;:\s@"]+(.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+.)+[^<>()[\].,;:\s@"]{2,})|)$/i
  );

  function onChangeEmail(e) {
    var _email = e.target.value;
    setUserEmail((prevState) => ({ ...prevState, value: _email }));

    if (validEmailRegex.test(_email)) {
      setUserEmail((prevState) => ({ ...prevState, valid: true }));
    } else {
      setUserEmail((prevState) => ({ ...prevState, valid: false }));
    }
    // check repeat
    if (_email === userEmailRepeat.value) {
      setIsValidEmailRepeat(true);
    } else {
      setIsValidEmailRepeat(false);
    }
    return true;
  }

  function onChangeEmailRepeat(e) {
    var _email = e.target.value;
    setUserEmailRepeat((prevState) => ({ ...prevState, value: _email }));

    if (validEmailRegex.test(_email)) {
      setUserEmailRepeat((prevState) => ({ ...prevState, valid: true }));
    } else {
      setUserEmailRepeat((prevState) => ({ ...prevState, valid: false }));
    }
    // check repeat
    if (userEmail.value === _email) {
      setIsValidEmailRepeat(true);
    } else {
      setIsValidEmailRepeat(false);
    }
    return true;
  }

  return (
    <div style={{ margin: 0, padding: 0 }}>
      {/* <button
                type='button'
                className='detail-btn-inscription-class'
                data-bs-toggle='modal'
                data-bs-target='#staticBackdrop'
            >
                Quiero que se repita
            </button> */}
      <div className='col px-2 mx-auto '>
        <button
          type='button'
          data-bs-toggle='modal'
          data-bs-target='#staticBackdrop'
          className={`btn  btn-sm ${styles.button_yellow}`}
        >
          Quiero que se repita
        </button>
      </div>
      <div
        className='modal fade'
        id='staticBackdrop'
        data-bs-backdrop='static'
        data-bs-keyboard='false'
        // tabindex='-1'
        aria-labelledby='staticBackdropLabel'
        aria-hidden='true'
      >
        <div className='modal-dialog'>
          <div className='modal-content'>
            <div
              className='modal-header'
              style={{ backgroundColor: '#FEC700' }}
            >
              <div className='modal-title' id='staticBackdropLabel'>
                <h2 className='form_header_title'>
                  ¿Te gustaría que se repita?
                </h2>
                <h4 className='form_header_subtitle float-start'>
                  Clase de <b className='bold'>{props.className}</b>
                  {/* Clase de <b style={{color: '#470467'}}>{props.className}</b> */}
                </h4>
              </div>
              <button
                id='topCloseButtonModal'
                type='button'
                className='btn-close'
                data-bs-dismiss='modal'
                aria-label='Close'
              ></button>
            </div>
            <div className='modal-body'>
              <form
                onSubmit={handleSubmit}
                id='registerToClassForm'
                className='needs-validation insc_modal_body'
              >
                <div className='mb-3'>
                  <label
                    htmlFor='inputUserName'
                    className='form-label insc_label_class float-start'
                  >
                    Nombre
                  </label>
                  <input
                    type='text'
                    className='form-control insc_input'
                    id='inputUserName'
                    placeholder='Ej: Javier Montenegro'
                    onChange={(e) => setUserName(e.target.value)}
                    required
                  />
                </div>
                <div className='form-outline mb-3'>
                  <label
                    htmlFor='phone'
                    className='form-label insc_label_class float-start'
                  >
                    Celular
                  </label>
                  <input
                    type='number'
                    id='phone'
                    className='form-control insc_input'
                    data-mdb-input-mask='+48 999-999-999'
                    placeholder='Ej: +56912345678'
                    onChange={(e) => setUserMobile(e.target.value)}
                  />
                </div>
                {/* Ubicacion */}
                <div className='mb-3'>
                  <label
                    htmlFor='inputUserUbication'
                    className='form-label insc_label_class float-start'
                  >
                    Comuna
                  </label>
                  <input
                    type='inputCity'
                    className='form-control insc_input'
                    id='inputUserUbication'
                    placeholder='Ej: Santiago, Chile'
                    onChange={(e) => setUbication(e.target.value)}
                    required
                  />
                </div>
                {/* Correo */}
                <div className='mb-3'>
                  <label
                    htmlFor='inputUserEmail'
                    className='form-label insc_label_class float-start'
                  >
                    Correo electrónico
                  </label>
                  <input
                    value={userEmail.value}
                    type='email'
                    className={`form-control insc_input ${
                      userEmail.valid === true
                        ? 'is-valid'
                        : userEmail.valid === false
                        ? 'is-invalid'
                        : ''
                    }`}
                    id='inputUserEmail'
                    placeholder='nombre@ejemplo.com'
                    onChange={onChangeEmail}
                    required
                  />
                  <div className='invalid-feedback'>Correo no válido.</div>
                </div>
                {/* Correo repetir */}
                <div className='mb-5'>
                  <label
                    htmlFor='inputUserEmailRepeat'
                    className='form-label insc_label_class float-start'
                  >
                    Repetir correo electrónico
                  </label>
                  <input
                    value={userEmailRepeat.value}
                    type='email'
                    className={`form-control insc_input ${
                      userEmailRepeat.valid === true
                        ? 'is-valid'
                        : userEmailRepeat.valid === false
                        ? 'is-invalid'
                        : ''
                    }`}
                    id='inputUserEmailRepeat'
                    placeholder='nombre@ejemplo.com'
                    onChange={onChangeEmailRepeat}
                    required
                  />
                  <div className='invalid-feedback'>Correo no válido.</div>
                </div>
                {/* ALERT */}
                {isValidEmailRepeat === true ? (
                  <div className='alert alert-success' role='alert'>
                    'Los correos coinciden!'
                  </div>
                ) : isValidEmailRepeat === false ? (
                  <div className='alert alert-danger' role='alert'>
                    'Los correos no coinciden!'
                  </div>
                ) : (
                  <></>
                )}

                {/* SUBMIT */}
                <div className='modal-footer'>
                  <button
                    type='submit'
                    className='btn insc_send_btn'
                    disabled={
                      !isValidEmailRepeat ||
                      !userEmailRepeat.valid ||
                      !userEmail.valid
                    }
                  >
                    Enviar
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default NotEnableStudentInscriptions;
