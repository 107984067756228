import React from 'react';
// import './Card.css';
// import styles from './SkeletonCards.module.css';
import { Skeleton } from '@mui/material';

const SkeletonCard = ({ clase }) => {
  return (
    <div class='col'>
      <div class={`card h-100 shadow-sm `}>
        <Skeleton variant='rectangular' width={'100%'} height={240} animation="wave"/>
        <div className={`card-body`}>
          <Skeleton variant='text' sx={{ fontSize: '2rem' }} width={'50%'} animation="wave"/>
          <Skeleton variant='body' height={100} width={'100%'} animation="wave"/>
          <Skeleton variant='text' sx={{ fontSize: '1rem' }} width={'25%'} animation="wave"/>
        </div>
      </div>
    </div>
  );
};

export default SkeletonCard;
