export default function NoWorshopSearch() {
  return (
    <div className='row d-flex justify-content-center pb-2 fw-light'>
      <div className='m-4 mx-auto text-center' style={{ color: 'black' }}>
        <div className='fs-5'>No existen talleres para tu búsqueda. </div>
        <div className='lead fs-6'> Inténtalo nuevamente con otra palabra! </div>
      </div>
    </div>
  );
}
