import { useState} from 'react';
import styles from './RequestNewClass.module.css';
import Swal from 'sweetalert2';

//import classesService from '.../services/classes.service';

function RequestNewClass(props) {
  const [email, setEmail] = useState("");
  const [description, setDescription] = useState("");

  const handleSendInterestedWorkshop = async (e) => {
    e.preventDefault();
    var payload = {
      email: email,
      description: description,
    };
    props.classService
        .postInterestedWorkshops(payload)
        .then(function (res) {

            Swal.fire({
                confirmButtonColor: '#6F119B',
                text: 'Gracias por tu interés, te contactaremos pronto',
                icon: 'success',
                showConfirmButton: true,
                confirmButtonText: 'Entendido',
            }).then(function (res) {
                Swal.close();
                window.location.replace("/");
            });
            return res;
        })
        .catch(function (res) {
            Swal.fire({
                showConfirmButton: true,
                confirmButtonColor: '#6F119B',
                // cancelButtonColor: '#6F119B',
                title: 'Error!',
                text: 'Tuvimos un error con nuestros sistemas, puedes comunicarte a través de nuestras redes sociales para solicitar ayuda.',
                icon: 'error',
                confirmButtonText: 'Entendido',
            }).then(function (res) {
              window.location.replace("/");
                Swal.close();
            });
        });
}; 
  return (
    <div
      className={`col-lg-5 col-md-6 col-10 mx-auto px-3 mb-5 ${styles.container_request}`}
    >
      <div className='pb-5 text-center'>
        {' '}
        <div className={`lead fs-2 mx-auto `}>
          ¿Te gustaría solicitar o dictar un taller?
        </div>
        <div className={`fs-2 fw-bold`}>¡Escríbenos!</div>
      </div>

      <div className={`mx-auto py-5`}>
        <form>
          <div class='mb-3 '>
            {/* <label for='exampleFormControlInput1' class='form-label text-bet'> */}
            <div className='row justify-content-between pb-2 fw-light'>
              <div className='col-auto '>Correo electrónico</div>
              <div className='col-auto'>{`(obligatorio)`}</div>
            </div>
            {/* </label> */}
            <input
              type='email'
              class='form-control '
              id='exampleFormControlInput1'
              placeholder='example@mail.com'
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          <div class='mb-3'>
            <div className='row justify-content-between pb-2 fw-light'>
              <div className='col-auto'>Cuéntanos tu solicitud</div>
              {/* <div className='col-auto'>{`(obligatorio)`}</div> */}
            </div>
            <textarea
              class='form-control'
              id='exampleFormControlTextarea1'
              rows='3'
              placeholder='Me gustaría solicitar un taller de...'
              onChange={(e) => setDescription(e.target.value)}
            ></textarea>
          </div>
        </form>
      </div>
      <div>
        <div className='d-grid col-lg-4 col-md-4 col-sm-4 col-12 mx-auto pb-4 mb-5'>
          <button
            type='button'
            class={`btn btn-primary btn-sm ${styles.button_submit}`}
            onClick={handleSendInterestedWorkshop}
          >
          Enviar Solicitud
          </button>
        </div>
      </div>
    </div>
  );
}

export default RequestNewClass;
