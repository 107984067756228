import { React } from 'react';
import styles from './Carousel.module.css';
import './Carousel.css';

function Carousel(props) {
  return (
    <div
      id='carouselExampleIndicators'
      className='carousel slide'
      data-ride='carousel'
    >
      {/* <ol className='carousel-indicators'>
        {props.images?.map((content, index) => {
          return (
            <li
              key={index}
              data-target='#carouselExampleIndicators'
              data-slide-to={index}
              className={index === 0 ? 'active' : ''}
            ></li>
          );
        })}
      </ol> */}
      <div className='carousel-inner mx-auto'>
        {props.images?.map((content, index) => {
          return (
            <div
              key={index}
              className={`carousel-item ${styles.carrousel_class} ${index === 0 ? 'active' : ''}`}
            >
              <img
                key={index}
                // className={`d-block rounded`}
                className={` `}
                src={
                  content === ''
                    ? 'https://test-apprende.s3.amazonaws.com/publicaci%C3%B3n+1.png'
                    : content.url
                }
                alt={content.description}
              />
            </div>
          );
        })}
      </div>
      {props.images?.length !== 1 ? (
        <div style={{ margin: 0 }}>
          <a
            className='carousel-control-prev'
            href='#carouselExampleIndicators'
            role='button'
            data-slide='prev'
          >
            <span
              className='carousel-control-prev-icon'
              aria-hidden='true'
            ></span>
            <span className='sr-only'></span>
          </a>
          <a
            className='carousel-control-next'
            href='#carouselExampleIndicators'
            role='button'
            data-slide='next'
          >
            <span
              className='carousel-control-next-icon'
              aria-hidden='true'
            ></span>
            <span className='sr-only'></span>
          </a>{' '}
        </div>
      ) : (
        <> </>
      )}
    </div>
  );
}

export default Carousel;
