import { React, useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import styles from "./BuyClass.module.css"
import Swal from 'sweetalert2';
import { track } from '@amplitude/analytics-browser';

import { dateValue, hourValue } from '../helpers';


import classesService from '../services/classes.service';

function BuyClass(props) {
    track('visita-compra-clase');
    let { id, discountCode } = useParams();
    const [className, setClassName] = useState("Taller de ejemplo")
    const [price, setPrice] = useState(0)
    const [fecha, setFecha] = useState("25/02/2023")
    const [hora, setHora] = useState("20:00")
    const [modalidad, setModalidad] = useState("Presencial")
    const [ubicacion, setUbicacion] = useState("Santa maria")
    const [profesor, setProfesor] = useState("Sol Araya")
    const [vacantes, setVacantes] = useState(0)
    const [quantity, setQuantity] = useState(1);
    const [maxQuantity, setMaxQuantity] = useState(10)
    const [totalPrice, setTotalPrice] = useState(0)

    const [userName, setUserName] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("");
    const [age, setAge] = useState("");
    const [genre, setGenre] = useState("");
    const [fileName, setFileName] = useState("");
    const [isMayorPrice, setIsMayorPrice] = useState(false);

    const [isLoadingBuy, setIsLoadingBuy] = useState(false)

    const setDetail = (detail) => {
        setClassName(detail.title)
        setPrice(parseInt(detail.precio))
        setFecha(dateValue(detail.dateAndHour))
        setHora(hourValue(detail.dateAndHour))
        setModalidad(detail.modalidad)
        setUbicacion(detail.ubicacion)
        setProfesor(detail.profesorName)
        setMaxQuantity(parseInt(detail.vacantes))
        setVacantes(parseInt(detail.vacantes))
        if (parseInt(detail.vacantes) === 0) {
            setQuantity(0)
            setTotalPrice(0)
            return
        }
        setQuantity(1)
        setTotalPrice(parseInt(detail.precio) * 1)
    }

    useEffect(() => {
        // fetch classes
        classesService
            .getcheckoutDetailClass(id)
            .then((resp) => {
                var _detail = resp.data.data;
                setDetail(_detail);
                discountUpdate(quantity)
            })
            .catch((e) => {
            });
    }, [id]);

    useEffect(() => {
        if (price > totalPrice / quantity) {
            setIsMayorPrice(true)
        } else {
            setIsMayorPrice(false)
        }
    }, [totalPrice]);

    const plusQuantity = () => {
        track('visita-compra-clase-suma-cantidad');
        let tmpQuantity = quantity + 1
        if (quantity < maxQuantity) {
            setQuantity(tmpQuantity)
            discountUpdate(tmpQuantity)
        }
    }
    const lessQuantity = () => {
        track('visita-compra-clase-resta-cantidad');
        let tmpQuantity = quantity - 1
        if (quantity > 1) {
            setQuantity(tmpQuantity)
            discountUpdate(tmpQuantity)
        }
    };

    const discountUpdate = (tmpQuantity) => {
        let payload = {}
        if (discountCode === undefined) {
            payload = {
                classId: parseInt(id),
                quantity: parseInt(tmpQuantity)
            }
        } else {
            payload = {
                classId: parseInt(id),
                quantity: parseInt(tmpQuantity),
                discountCode: discountCode

            }
        }
        classesService
            .postDiscount(payload)
            .then((resp) => {
                var _newPrice = resp.data.data;
                setTotalPrice(_newPrice);
            })
            .catch((e) => {
            });
    }

    const uploadElectronicTransfer = (file) => {
        track('visita-compra-clase-subida-archivo');
        file = file.target.files[0];
        const formData = new FormData();
        formData.append('file', file);
        classesService
            .uploadFileElectronicTransfer(formData)
            .then((res) => {
                if (res.data.code === 200) {
                    setFileName(res.data.data.Filename)
                }
            });
    }

    const sendFormToBuyClass = async (e) => {
        e.preventDefault();
        setIsLoadingBuy(true)
        var payload = {
            classId: parseInt(id),
            userName: userName,
            quantity: parseInt(quantity),
            phoneNumber: phoneNumber,
            email: userEmail.value,
            age: age,
            genre: genre,
            transferFileName: fileName,
            discountCode: discountCode
        };
        if (selectedMercadoPagoAction) {
            await classesService
                .postMercadoPagoPreference(payload)
                .then(function (res) {
                    window.location.replace(res.data.data)
                })
                .catch(function (res) {
                    Swal.fire({
                        showConfirmButton: true,
                        confirmButtonColor: '#6F119B',
                        // cancelButtonColor: '#6F119B',
                        timer: 3000,
                        title: 'Error!',
                        text: 'Tuvimos un error con nuestros sistemas, puedes comunicarte a través de nuestras redes sociales para solicitar ayuda.',
                        icon: 'error',
                        confirmButtonText: 'Entendido',
                    }).then(function (res) {
                        //Swal.close();
                    });
                });
        }
        if (selectedTransferAction) {
            await classesService
                .buyClassWithElectronicTransfer(payload)
                .then(function (res) {

                    Swal.fire({
                        confirmButtonColor: '#6F119B',
                        text: 'Reserva hecha exitosamente, hemos recibido tu comprobante de pago, te enviaremos mas información a tu correo electronico',
                        icon: 'success',
                        showConfirmButton: true,
                        confirmButtonText: 'Entendido',
                    }).then(function (res) {
                        Swal.close();
                        window.location.replace("/");
                    });;
                })
                .catch(function (res) {
                    Swal.fire({
                        showConfirmButton: true,
                        confirmButtonColor: '#6F119B',
                        // cancelButtonColor: '#6F119B',
                        timer: 3000,
                        title: 'Error!',
                        text: 'Tuvimos un error con nuestros sistemas, puedes comunicarte a través de nuestras redes sociales para solicitar ayuda.',
                        icon: 'error',
                        confirmButtonText: 'Entendido',
                    }).then(function (res) {
                        //Swal.close();
                    });
                });
        }
        setIsLoadingBuy(false)
    };
    const [userEmail, setUserEmail] = useState({ value: '', valid: undefined });
    const [userEmailRepeat, setUserEmailRepeat] = useState({
        value: '',
        valid: undefined,
    });

    const [isValidEmailRepeat, setIsValidEmailRepeat] = useState(undefined);

    const validEmailRegex = RegExp(
        /^((([^<>()[\].,;:\s@"]+(.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+.)+[^<>()[\].,;:\s@"]{2,})|)$/i
    );

    function onChangeEmail(e) {
        var _email = e.target.value;
        setUserEmail((prevState) => ({ ...prevState, value: _email }));

        if (validEmailRegex.test(_email)) {
            setUserEmail((prevState) => ({ ...prevState, valid: true }));
        } else {
            setUserEmail((prevState) => ({ ...prevState, valid: false }));
        }
        // check repeat
        if (_email === userEmailRepeat.value) {
            setIsValidEmailRepeat(true);
        } else {
            setIsValidEmailRepeat(false);
        }
        return true;
    }

    function onChangeEmailRepeat(e) {
        var _email = e.target.value;
        setUserEmailRepeat((prevState) => ({ ...prevState, value: _email }));

        if (validEmailRegex.test(_email)) {
            setUserEmailRepeat((prevState) => ({ ...prevState, valid: true }));
        } else {
            setUserEmailRepeat((prevState) => ({ ...prevState, valid: false }));
        }
        // check repeat
        if (userEmail.value === _email) {
            setIsValidEmailRepeat(true);
        } else {
            setIsValidEmailRepeat(false);
        }
        return true;
    }

    const [selectedTransferAction, setSelectedTransferAction] = useState(true);
    const [selectedMercadoPagoAction, setSelectedMercadoPagoAction] = useState(false);

    function selectTransfer() {
        track('visita-compra-clase-select-transfer');
        setSelectedTransferAction(true)
        setSelectedMercadoPagoAction(false)
    }

    function selectMercadoPago() {
        track('visita-compra-clase-select-mercado-pago');
        setSelectedTransferAction(false)
        setSelectedMercadoPagoAction(true)
    }

    useEffect(() => {
        disabledBuyClass();
    }, [selectedMercadoPagoAction, selectedTransferAction]);


    function PaymentTransferStyle() {
        if (selectedTransferAction) {
            return (<button type="button" className={styles.transferButton} onClick={selectTransfer}>Transferencia</button>)
        } else {
            return (<button type="button" className={styles.cardPaymentButton} onClick={selectTransfer}>Transferencia</button>)
        }
    }

    function PaymentMercadoPagoStyle() {
        if (selectedMercadoPagoAction) {
            return (<button type="button" className={styles.transferButton} onClick={selectMercadoPago}>Mercado Pago</button>
            )
        } else {
            return (<button type="button" className={styles.cardPaymentButton} onClick={selectMercadoPago}>Mercado Pago</button>)
        }
    }

    function IfTransferSelected() {
        if (selectedTransferAction === true) {
            return (
                <>
                    <div class="col-md-6">
                        <h5>Datos de transferencia</h5>
                        <p>Nombre: Apprende SPA <br></br>
                            Cuenta: 00-328-08395-10 <br></br>
                            Banco: Banco de Chile <br></br>
                            Rut: 19.956.785-3 <br></br>
                            Correo: cl.apprende@gmail.com</p>
                    </div>
                    <div class="col-md-6 mb-3">
                        <label for="formFileSm" class="form-label">Sube tu comprobante</label>
                        <input class="form-control form-control-sm" id="formFileSm" type="file" onChange={uploadElectronicTransfer} />
                    </div>
                </>
            )
        } else {
            return (<></>)
        }

    }

    const [disabledBuyButton, setDisabledBuyButton] = useState(true)

    function disabledBuyClass() {
        if (selectedTransferAction === true) {
            if (fileName === "" || userEmail === "" || userEmailRepeat.value === "" || userName === "") {
                setDisabledBuyButton(true)
            } else {
                setDisabledBuyButton(false)

            }
        }
        if (selectedMercadoPagoAction === true) {
            if (userEmail === "" || userEmailRepeat.value === "" || userName === "" || age === "" || genre === "") {
                setDisabledBuyButton(true)
            } else {
                setDisabledBuyButton(false)
            }
        }
    }

    useEffect(() => {
        disabledBuyClass();
    }, [userName, userEmail, userEmailRepeat, age, genre, fileName, phoneNumber]);

    return (
        <div className={`container ${styles.container}`}>
            <main>
                <div class="py-5 text-center">
                    <img class="d-block mx-auto mb-4" src="/logo_apprende_redondo.png" alt="" width="200" height="200" />
                    <h2>Comprar {className}</h2>
                </div>

                <div class="row g-5">
                    <div class="col-md-5 col-lg-4 order-md-last">
                        <h4 class="d-flex justify-content-between align-items-center mb-3">
                            <span>Tu compra</span>
                        </h4>
                        <ul class="list-group mb-3">
                            <li class="list-group-item d-flex justify-content-between lh-sm">
                                <div>
                                    <h6 class="my-0">{className}</h6>
                                </div>
                            </li>
                            <li class="list-group-item d-flex justify-content-between lh-sm">
                                <div>
                                    <h6 class="my-0">Precio</h6>
                                </div>
                                <span class="text-muted">${price}</span>
                            </li>
                            <li class="list-group-item d-flex justify-content-between lh-sm">
                                <div>
                                    <h6 class="my-0">Fecha</h6>
                                </div>
                                <span class="text-muted">{fecha}</span>
                            </li>
                            <li class="list-group-item d-flex justify-content-between lh-sm">
                                <div>
                                    <h6 class="my-0">Hora</h6>
                                </div>
                                <span class="text-muted">{hora}</span>
                            </li>
                            <li class="list-group-item d-flex justify-content-between lh-sm">
                                <div>
                                    <h6 class="my-0">Modalidad</h6>
                                </div>
                                <span class="text-muted">{modalidad}</span>
                            </li>
                            <li class="list-group-item d-flex justify-content-between lh-sm">
                                <div>
                                    <h6 class="my-0">Vacantes</h6>
                                </div>
                                <span class="text-muted">{vacantes}</span>
                            </li>
                            <li class="list-group-item d-flex justify-content-between lh-sm">
                                <div>
                                    <h6 class="my-0">Ubicación</h6>
                                </div>
                                <span class="text-muted">{ubicacion}</span>
                            </li>
                            <li class="list-group-item d-flex justify-content-between lh-sm">
                                <div>
                                    <h6 class="my-0">Tallerista</h6>
                                </div>
                                <span class="text-muted">{profesor}</span>
                            </li>
                            <li class="list-group-item d-flex justify-content-between lh-sm">
                                <div>
                                    <h6 class="my-0">Cantidad</h6>
                                </div>
                                <div class="btn-group" role="group" aria-label="Basic example">
                                    <button type="button" className={styles.quantityButtonLeft} onClick={lessQuantity}>-</button>
                                    <button type="button" className={styles.quantityButton}>{quantity}</button>
                                    <button type="button" className={styles.quantityButtonRight} onClick={plusQuantity}>+</button>
                                </div>
                            </li>
                        </ul>

                        <form class="card p-2">
                            <div className='d-flex justify-content-between lh-sm'>
                                <span>Total (clp)</span>
                                <strong>${totalPrice}</strong>
                            </div>
                            {isMayorPrice ? <div className='d-flex justify-content-between lh-sm'>
                                <span>Se ha aplicado un descuento</span>
                            </div> : <></>}

                        </form>
                    </div>
                    <div class="col-md-7 col-lg-8">
                        <h4 class="mb-3">1. Completa tus datos</h4>
                        <form class="needs-validation" novalidate onSubmit={sendFormToBuyClass}>
                            <div class="row g-3">
                                <div class="col-sm-12">
                                    <label for="firstName" class="form-label">Nombre Completo</label>
                                    <input type="text" class="form-control" id="firstName" placeholder="Ej: Juan Pérez Soto" onChange={(e) => setUserName(e.target.value)} />
                                    <div class="invalid-feedback">
                                        Tu nombre completo es requerido.
                                    </div>
                                </div>

                                <div class="col-12">
                                    <label for="username" class="form-label">Número de celular</label>
                                    <div class="input-group has-validation">
                                        <input type="text" class="form-control" id="username" placeholder="Ej: +56912345678" onChange={(e) => setPhoneNumber(e.target.value)} />
                                        <div class="invalid-feedback">
                                            Tu número de teléfono es requerido.
                                        </div>
                                    </div>
                                </div>

                                {/* Correo */}
                                <div className='col-12'>
                                    <label
                                        htmlFor='inputUserEmail'
                                        className='form-label'
                                    >
                                        Correo electronico
                                    </label>
                                    <input
                                        value={userEmail.value}
                                        type='email'
                                        className={`form-control ${userEmail.valid === true
                                            ? 'is-valid'
                                            : userEmail.valid === false
                                                ? 'is-invalid'
                                                : ''
                                            }`}
                                        id='inputUserEmail'
                                        placeholder='nombre@ejemplo.com'
                                        onChange={onChangeEmail}
                                        required
                                    />
                                    <div className='invalid-feedback'>Correo no válido.</div>
                                </div>
                                {/* Correo repetir */}
                                <div className='col-12'>
                                    <label
                                        htmlFor='inputUserEmailRepeat'
                                        className='form-label'
                                    >
                                        Repetir correo electronico
                                    </label>
                                    <input
                                        value={userEmailRepeat.value}
                                        type='email'
                                        className={`form-control ${userEmailRepeat.valid === true
                                            ? 'is-valid'
                                            : userEmailRepeat.valid === false
                                                ? 'is-invalid'
                                                : ''
                                            }`}
                                        id='inputUserEmailRepeat'
                                        placeholder='nombre@ejemplo.com'
                                        onChange={onChangeEmailRepeat}
                                        required
                                    />
                                    <div className='invalid-feedback'>Correo no válido.</div>
                                </div>
                                {/* ALERT */}
                                {isValidEmailRepeat === true ? (
                                    <div className='alert alert-success' role='alert'>
                                        'Los correos coinciden!'
                                    </div>
                                ) : isValidEmailRepeat === false ? (
                                    <div className='alert alert-danger' role='alert'>
                                        'Los correos no coinciden!'
                                    </div>
                                ) : (
                                    <></>
                                )}

                                <div class="col-md-6">
                                    <label for="edad" class="form-label">Edad</label>
                                    <input type="number" class="form-control" id="email" placeholder="Ej: 25" onChange={(e) => setAge(e.target.value)} />
                                </div>

                                <div class="col-md-6">
                                    <label for="state" class="form-label">Género</label>
                                    <select class="form-select" id="state" onChange={(e) => setGenre(e.target.value)}>
                                        <option value="">Elige...</option>
                                        <option>Femenino</option>
                                        <option>Masculino</option>
                                        <option>No binario</option>
                                        <option>Prefiero no decirlo</option>
                                        <option>Otro</option>
                                    </select>
                                    <div class="invalid-feedback">
                                        Género requerido
                                    </div>
                                </div>
                            </div>

                            <hr class="my-4" />

                            <h4 class="mb-3">2. Paga tu clase</h4>

                            <div class="row gy-3">
                                <h5>Métodos de pago</h5>
                                <div class="col-md-6">
                                    <PaymentTransferStyle />
                                </div>

                                <div class="col-md-6">
                                    <PaymentMercadoPagoStyle />
                                    <small class="text-white row">* Serás redirigido al sitio web de mercado pago</small>
                                </div>

                                <IfTransferSelected />
                            </div>

                            <hr class="my-4" />
                            {vacantes === 0 ? <AlertNotCapacity /> : null}
                            {userName === "" ? <AlertNotName /> : null}
                            {phoneNumber === "" ? <AlertNotPhoneNumber /> : null}
                            {userEmail.value === "" ? <AlertNotEmail /> : null}
                            {age === "" ? <AlertNotAge /> : null}
                            {genre === "" ? <AlertNotGenre /> : null}
                            {fileName === "" && selectedTransferAction ? <AlertNotFile /> : null}

                            <button class="w-100 btn btn-lg" disabled={disabledBuyButton || isLoadingBuy} style={{ backgroundColor: "#FEC700" }}
                                type="submit">Compra tu clase</button>
                        </form>
                        <div style={{ height: "100px" }}>

                        </div>
                    </div>
                </div>
            </main>
        </div>
    )
}

const AlertNotCapacity = function (params) {
    return (<div class="alert alert-warning" role="alert">
        Clase sin vacantes</div>)
}
const AlertNotName = function (params) {
    return (<div class="alert alert-warning" role="alert">
        Por favor escribe tu nombre</div>)
}
const AlertNotPhoneNumber = function (params) {
    return (<div class="alert alert-warning" role="alert">
        Por favor escribe tu numero de telefono</div>)
}
const AlertNotEmail = function (params) {
    return (<div class="alert alert-warning" role="alert">
        Por favor escribe tu correo</div>)
}
const AlertNotAge = function (params) {
    return (<div class="alert alert-warning" role="alert">
        Por favor escribe tu edad</div>)
}
const AlertNotGenre = function (params) {
    return (<div class="alert alert-warning" role="alert">
        Por favor escribe tu genero</div>)
}
const AlertNotFile = function (params) {
    return (<div class="alert alert-warning" role="alert">
        Para comprar sube tu comprobante</div>)
}

export default BuyClass;