import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import About from './pages/About';
import PrivacyPolicy from './pages/PrivacyPolicy';
import Clase from './pages/Clase';
import Event from './pages/Event';
import BuyClass from './pages/BuyClass';
import ErrorPage from './pages/ErrorPage';
// import NavBar from './components/navbar/NavBar';
import NewFooter from './components/footer/newFooter';
import { init } from '@amplitude/analytics-browser';
import NewHome from './pages/NewHome';
import NavBarSimple from './components/navbar_simple/NavBarSimple';
import Catalogue from './pages/Catalogue';
import Success from './pages/Payments/Success';
import Failed from './pages/Payments/Failed';
import Pending from './pages/Payments/Pending';

function App() {

  init(process.env.REACT_APP_AMPLITUDE_API_KEY);
  return (
    <>
      <Router>
        {/* <NavBar /> */}
        <NavBarSimple />
        <Routes>
          <Route path='/' element={<NewHome />} />
          <Route path='/about' element={<About />} />
          <Route path='/privacy-policy' element={<PrivacyPolicy />} />
          <Route path='/clase/:id' element={<Clase />} />
          <Route path='/event/:id' element={<Event />} />
          <Route path='/catalogo' element={<Catalogue />} />
          <Route path='/buy-class/:id/:discountCode?' element={<BuyClass/>}/>
          <Route path='*' element={<ErrorPage />} />
          <Route path='/payment/success' element={<Success/>}/>
          <Route path='/payment/failed' element={<Failed/>}/>
          <Route path='/payment/pending' element={<Pending/>}/>
        </Routes>
        <NewFooter />
      </Router>
    </>
  );
}

export default App;
