import React from 'react';
import './NavBarSimple.css';
import { Link, useLocation } from 'react-router-dom';

const routes_nav = [
  { title: 'Home', route: '/' },
  { title: 'Nosotros', route: '/about' },
  // { title: 'Seguir mi clase', route: '/' },
  // { title: 'Para empresas', route: '/business' },
  // { title: 'Contacto', route: '/contact' },
];

function NavBarSimple() {
  let location = useLocation();
  let pathname = location.pathname;
  
  return (
    <>
      <nav
        class='navbar navbar-expand-md navbar-custom mb-auto' //mb-auto
        aria-label='Fourth navbar example'
      >
        <div class='container-fluid'>
          <Link
            style={{
              color: 'rgba(1, 1, 1, 0)',
              padding: '0',
              margin: '0',
            }}
            to='/'
          >
            <a href='/' className='navbar-brand logo'>
              <img
                id='main-logo'
                src={require('../../images/logo_apprende.png')}
                alt='apprende_logo'
              />
              Apprende
            </a>
          </Link>
          <button
            class='navbar-toggler'
            type='button'
            data-bs-toggle='collapse'
            data-bs-target='#navbarsExample04'
            aria-controls='navbarsExample04'
            aria-expanded='false'
            aria-label='Toggle navigation'
          >
            <span class='navbar-toggler-icon'></span>
          </button>
          <div
            class='collapse navbar-collapse justify-content-end'
            id='navbarsExample04'
          >
            <ul class='navbar-nav ml-auto mb-2 mb-md-0 '>
              {routes_nav.map((item, index) => (
                <>
                  <li class='nav-item '>
                    <Link
                      style={{
                        textDecoration: 'none'
                      }}
                      to={item.route}
                    >
                      <a
                        className={`nav-link ${
                          pathname === item.route ? 'active' : ''
                        }`}
                        href={item.route}
                      >
                        {item.title}
                      </a>
                    </Link>
                  </li>
                </>
              ))}
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
}

export default NavBarSimple;
