import { React } from 'react';
import Carousel from './Carousel';
import Inscribirse from './Inscribirse';
import NotEnableStudentInscriptions from './NotEnableStudentInscriptions';
import styles from './DetailClassOptional.module.css';
import InterestedCompanyInClass from './InterestedCompanyInClass';
import AboutWorkshop from './AboutWorkshop';
import { dateValue, hourValue } from '../../helpers';

import { Link } from 'react-router-dom';

function DetailClassOptional(props) {
  let today = new Date();


  const InscribirseButton = () => {
    let datesBoolean = props.detail?.dates?.map((content) => {return (new Date(content.DateClass) > today)})
    console.log(datesBoolean)
    let isEnable = false
    for (var index in datesBoolean) {
      if(datesBoolean[index] === true) {
        isEnable = true
        break
      }
    }
    if (isEnable) {
      if (props.detail?.precio === '0') {
        return (
          <Inscribirse
            id={props.id}
            className={props.detail?.title}
            comuna={props.detail?.ubicacion}
            fecha={dateValue(props.detail?.dateAndHour)}
            hora={hourValue(props.detail?.dateAndHour)}
            formType={props.detail?.formType}
          />
        );
      } else {
        return (
          <Link
            style={{
              color: 'white',
              padding: '0',
              margin: '0',
              textDecoration: 'none',
            }}
            to={`/buy-class/${props.id}`}
            preventScrollReset={false}
          >
            <div className='col px-2 mx-auto '>
              <button
                type='button'
                className={`btn  btn-sm ${styles.button_yellow}`}
              >
                Comprar clase
              </button>
            </div>
          </Link>
        );
      }
    } else {
      return (
        <>
          <NotEnableStudentInscriptions
            id={props.id}
            className={props.detail?.title}
          />
          <InterestedCompanyInClass
            id={props.id}
            className={props.detail?.title}
          />
        </>
      );
    }
  };

  return (
    <>
      {/* <div
        className={`justify-content-end list-unstyled d-flex ${styles.meteoritos_container}`}
      >
        <Meteoritos className={styles.meteoritos} />
      </div> */}
      <div className='row' style={{ margin: 0, padding: 0 }}>
        <div className={`col ${styles.detail_title_class}`}>
          {props.detail?.title}
        </div>
        <div className='row' style={{ margin: 0, padding: 0 }}>
          {/* class detail */}
          <div
            className='col-12 col-sm-12 col-md-6 order-2 order-sm-2  order-md-1'
            style={{ margin: 0, padding: 0 }}
          >
            <div
              className='col-12 col-lg-10 mx-auto'
              style={{ margin: 0, padding: 0 }}
            >
              <div className='row ' style={{ margin: 0, padding: 0 }}>
                {/* <div style={{ color: '#ffff' }} className='col order-5 order-sm-1'> */}
                <div className={`${styles.detail_title_description_class}`}>
                  Descripción
                </div>
                <div className={`${styles.detail_description_class}`}>
                  {props.detail?.description}
                </div>
                {/* </div> */}
              </div>
              <div className='row ' style={{ margin: 0, padding: 0 }}>
                {/* <button title='Inscribirse'>Inscribirse</button> */}
                <div
                  className={`d-flex justify-content-center m-auto offset-md-4 ${styles.detail_btn_inscription_class}`}
                >
                  <div className='d-flex align-items-end'>
                    <div className='d-flex justify-content-center'></div>
                    <InscribirseButton />
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* carousel */}
          <div
            className='col-12 col-sm-12 col-md-6 order-1 order-sm-1 order-md-2'
            style={{ margin: 0, padding: 0 }}
          >
            {/* <div className='row'> */}
            <div className={`mx-auto ${styles.detail_multimedia_class} `}>
              <Carousel images={props.detail?.multimediaClass} />
            </div>
            {/* </div> */}
          </div>
          {/* contents */}
          <div
            className='row mb-5 mt-5 order-3 order-md-3 order-sm-3'
            style={{ margin: 0, padding: 0 }}
          >
            <div className='row' style={{ margin: 0, padding: 0 }}>
              <div className='col' style={{ margin: 0, padding: 0 }}>
                <div className={`${styles.detail_subtitle_class}`}>
                  Contenidos
                </div>
                <ul
                  className={`${styles.detail_content_class} ${styles.ul_class}`}
                >
                  {props.detail?.contenido?.map((content, index) => {
                    return (
                      <li key={index} className={`${styles.li_class}`}>
                        {content}
                      </li>
                    );
                  })}
                </ul>
              </div>
              <div className='col' style={{ margin: 0, padding: 0 }}>
                <AboutWorkshop details={props.detail} />
              </div>
            </div>
          </div>
        </div>
        {/* professor details */}
        <div
          className='row py-5'
          style={{ background: '#FFFFFF', margin: 0, padding: 0 }}
        >
          {/* Columna izquierda */}
          <div className='col row-cols mx-5'>
            <div className={`${styles.detail_title_author}`}>
              Acerca del Tutor
            </div>
            {/* <div>{props.detail?.profesorData.professorPicture}</div> */}

            {props.detail?.profesorData.professorPicture === '' ? (
              <div
                className={`${styles.professor_without_pic} mx-auto mb-3 mt-3`}
              ></div>
            ) : (
              <div
                className={`${styles.detail_professorpicture} mx-auto mb-3 mt-3`}
              >
                <img
                  src={props.detail?.profesorData.professorPicture}
                  alt='First slide'
                />
              </div>
            )}

            <div className={`text-center ${styles.detail_professorname}`}>
              {props.detail?.profesorData.professorName}
            </div>
            {props.detail?.profesorData?.instagram !== '' ||
              props.detail?.profesorData?.tikTok !== '' ? (
              <ul className='text-center' style={{ marginRight: '30px' }}>
                <li style={{ display: 'inline-block' }}>
                  {props.detail?.profesorData?.instagram !== '' ? (
                    <a
                      className='text-muted'
                      href={props.detail?.profesorData?.instagram}
                      target='_blank'
                      rel='noopener noreferrer'
                      style={{ display: 'inline-block' }}
                    >
                      <svg className={styles.bi} width='24' height='24'>
                        <path d='M8 0C5.829 0 5.556.01 4.703.048 3.85.088 3.269.222 2.76.42a3.917 3.917 0 0 0-1.417.923A3.927 3.927 0 0 0 .42 2.76C.222 3.268.087 3.85.048 4.7.01 5.555 0 5.827 0 8.001c0 2.172.01 2.444.048 3.297.04.852.174 1.433.372 1.942.205.526.478.972.923 1.417.444.445.89.719 1.416.923.51.198 1.09.333 1.942.372C5.555 15.99 5.827 16 8 16s2.444-.01 3.298-.048c.851-.04 1.434-.174 1.943-.372a3.916 3.916 0 0 0 1.416-.923c.445-.445.718-.891.923-1.417.197-.509.332-1.09.372-1.942C15.99 10.445 16 10.173 16 8s-.01-2.445-.048-3.299c-.04-.851-.175-1.433-.372-1.941a3.926 3.926 0 0 0-.923-1.417A3.911 3.911 0 0 0 13.24.42c-.51-.198-1.092-.333-1.943-.372C10.443.01 10.172 0 7.998 0h.003zm-.717 1.442h.718c2.136 0 2.389.007 3.232.046.78.035 1.204.166 1.486.275.373.145.64.319.92.599.28.28.453.546.598.92.11.281.24.705.275 1.485.039.843.047 1.096.047 3.231s-.008 2.389-.047 3.232c-.035.78-.166 1.203-.275 1.485a2.47 2.47 0 0 1-.599.919c-.28.28-.546.453-.92.598-.28.11-.704.24-1.485.276-.843.038-1.096.047-3.232.047s-2.39-.009-3.233-.047c-.78-.036-1.203-.166-1.485-.276a2.478 2.478 0 0 1-.92-.598 2.48 2.48 0 0 1-.6-.92c-.109-.281-.24-.705-.275-1.485-.038-.843-.046-1.096-.046-3.233 0-2.136.008-2.388.046-3.231.036-.78.166-1.204.276-1.486.145-.373.319-.64.599-.92.28-.28.546-.453.92-.598.282-.11.705-.24 1.485-.276.738-.034 1.024-.044 2.515-.045v.002zm4.988 1.328a.96.96 0 1 0 0 1.92.96.96 0 0 0 0-1.92zm-4.27 1.122a4.109 4.109 0 1 0 0 8.217 4.109 4.109 0 0 0 0-8.217zm0 1.441a2.667 2.667 0 1 1 0 5.334 2.667 2.667 0 0 1 0-5.334z' />
                      </svg>
                    </a>
                  ) : (
                    <></>
                  )}
                </li>
                <li style={{ display: 'inline-block' }}>
                  {props.detail?.profesorData?.tikTok !== '' ? (
                    <a
                      className='text-muted'
                      href={props.detail?.profesorData?.tikTok}
                      target='_blank'
                      rel='noopener noreferrer'
                    >
                      <svg
                        xmlns='http://www.w3.org/2000/svg'
                        width='16'
                        height='16'
                        fill='currentColor'
                        class='bi bi-tiktok'
                        viewBox='0 0 16 16'
                      >
                        <path d='M9 0h1.98c.144.715.54 1.617 1.235 2.512C12.895 3.389 13.797 4 15 4v2c-1.753 0-3.07-.814-4-1.829V11a5 5 0 1 1-5-5v2a3 3 0 1 0 3 3V0Z' />
                      </svg>
                    </a>
                  ) : (
                    <></>
                  )}
                </li>
              </ul>
            ) : (
              <></>
            )}
            <div className={`text-center ${styles.detail_professoroccupation}`}>
              {props.detail?.profesorData.professorOccupation}
            </div>
          </div>
          {/* Columna derecha */}
          <div className={`col row-cols mx-5`}>
            <div className={styles.container_experience}>
              <div className={`text-left ${styles.detail_title_author}`}>
                Experiencia
              </div>
              <div className={` ${styles.detail_experience_author}`}>
                {props.detail?.profesorData.professorExperience}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default DetailClassOptional;
