import styles from './Error.module.css'

export default function NoWorshop({ category }) {
  return (
    <div className={category ? styles.noworshop_component_white : styles.noworshop_component_yellow }>
      <div className='row d-flex justify-content-center pb-2 fw-light'>
        <div className='m-4 mx-auto text-center'>
          <div className='fs-5'>No existen talleres en esta categoría.</div>
          <div className='lead fs-6'>
            Estamos trabajando para ofrecerte nuevos talleres!
          </div>
        </div>
      </div>
    </div>
  );
}
