import React from 'react';
import styles from './newFooter.module.css';

const apprende_number = '1223123123';
const apprende_mail = 'cl.apprende@gmail.com';
const apprende_linkedin = 'apprendecl';

const social_media = {
  mailto: `mailto:${apprende_mail}`,
  telto: `tel:${apprende_number}`,
  linkedinto: `https://www.linkedin.com/company/${apprende_linkedin}`,
  linkedinview: `${apprende_linkedin}`,
};

const btn_social_media = [
  {
    src: require('../../images/footer/facebook.png'),
    url: 'https://www.facebook.com/apprende.cl',
  },
  // {
  //   src: require('../../images/footer/whatsapp.png'),
  //   url: `https://wa.me/${social_media.apprende_number}`,
  // },
  {
    src: require('../../images/footer/instagram.png'),
    url: 'https://www.instagram.com/apprendechile/?hl=es',
  },
  {
    src: require('../../images/footer/linkedin.png'),
    url: `https://www.linkedin.com/company/${apprende_linkedin}`,
  },
];

function NewFooter() {
  const mail_svg = (
    <svg className={styles.bi} width='24' height='24'>
      <g transform='translate(2.000000, 24) scale(0.004,-0.004)'>
        <path d='M301 4340 c-119 -43 -207 -123 -264 -239 l-32 -66 0 -1475 0 -1475 32 -66 c58 -119 148 -198 269 -239 58 -20 87 -20 2274 -18 l2215 3 70 34 c92 45 171 124 216 216 l34 70 0 1475 0 1475 -34 70 c-45 92 -124 171 -216 216 l-70 34 -2220 2 c-2171 2 -2221 2 -2274 -17z m3314 -1259 c-1066 -1065 -997 -1004 -1092 -986 -34 6 -146 114 -1018 986 l-980 979 2035 0 2035 0 -980 -979z m-2673 -1163 l-642 -643 0 1285 0 1285 642 -643 643 -642 -643 -642z m3878 640 l0 -1283 -642 642 -643 643 640 640 c352 352 641 640 642 640 2 0 3 -577 3 -1282z m-2480 -725 c148 -70 290 -70 440 -1 57 27 93 58 300 269 l236 238 639 -639 640 -640 -2035 0 -2035 0 640 640 640 640 235 -238 c205 -208 243 -242 300 -269z' />
      </g>
    </svg>
  );

  return (
    <div className='mt-auto'>
      <div className={styles.footer_class}>
        <footer className='d-flex flex-wrap justify-content-between align-items-center py-3 p-4 mt-auto'>
          {/* seccion logo */}
          {/* <div className='col col-md-4 col-lg-5 ps-3'> */}
          <div className='col col-md-2 ps-3'>
            <div className='row align-items-center'>
              <div className='col-3 col-md-5 col-lg-5'>
                <div className={styles.contacto_footer}>Contacto</div>
              </div>

              {/* <div className='col-3 col-md-5 col-lg-6'>
                <div className={` row  ${styles.phone_container}`}>
                  <div className='col-1 m-0 me-3 d-flex align-items-center'>
                    <a
                      className='text-muted'
                      href={social_media.telto}
                      target='_blank'
                      rel='noopener noreferrer'
                    >
                      <img
                        width={15}
                        height={15}
                        src={phone_logo}
                        alt='logo apprende'
                      />
                    </a>
                  </div>

                  <div className='col m-0 p-0 '>
                    <div className='row justify-content-end'>
                      <a
                        target='_blank'
                        rel='noopener noreferrer'
                        href={social_media.telto}
                      >
                        {apprende_number}
                      </a>
                    </div>
                  </div>
                </div> */}

              {/* <div className='col-3 col-md-5 col-lg-6'>
                <div className={` row  ${styles.phone_container}`}>
                  <div className='col-1 m-0 me-3 d-flex align-items-center'>
                    <a
                      href={social_media.telto}
                      target='_blank'
                      rel='noopener noreferrer'
                    >
                      {linkedin_logo}
                    </a>
                  </div>

                  <div className='col'>
                    <div className='row justify-content-end'>
                      <a
                        target='_blank'
                        rel='noopener noreferrer'
                        href={social_media.linkedinto}
                      >
                        {/* {social_media.linkedinview} 
                      </a>
                    </div>
                  </div>
                </div>
              </div> */}
            </div>
          </div>

          {/* seccion correo */}
          <div className='col-1 col-md-4 col-lg-3 ps-3'>
            <div className={`row ${styles.mail_container}`}>
              {/* <div className='col-1 m-0 me-3 d-flex align-items-center pb-2'>
                <a href={social_media.mailto}>
                  <img
                    width={20}
                    height={15}
                    src={mail_logo}
                    alt='logo apprende'
                  />
                </a>
              </div> */}
              <div className='col-1 m-0 me-3 d-flex align-items-center'>
                <a
                  className='text-muted'
                  href={social_media.telto}
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  {mail_svg}
                </a>
              </div>

              <div className='col'>
                <a href={social_media.mailto}>{apprende_mail} </a>
              </div>
              <div className='col'>
                <a href="/privacy-policy">Politica de privacidad</a>
              </div>
            </div>
          </div>
          <ul className='nav col col-md-2 justify-content-end list-unstyled d-flex'>
            {btn_social_media.map((item, index) => (
              <li className='ms-3' key={index}>
                <a
                  className='text-muted'
                  href={item.url}
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  <img
                    width={30}
                    height={30}
                    src={item.src}
                    alt='logo apprende'
                  />
                </a>
              </li>
            ))}
          </ul>
        </footer>
      </div>
    </div>
  );
}

export default NewFooter;
