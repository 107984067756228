import { React, useState } from 'react';
import './Inscribirse.css';
import Swal from 'sweetalert2';
import classesService from '../../services/classes.service';
import styles from './CustomButton.module.css';

function InterestedCompanyInClass(props) {
  const id = props.id;
  const [companyName, setCompanyName] = useState();
  const [userUbication, setUbication] = useState();
  const [userMobile, setUserMobile] = useState();

  const [userEmail, setUserEmail] = useState({ value: '', valid: undefined });

  const handleSubmit = async (e) => {
    e.preventDefault();
    var payload = {
      classId: parseInt(id),
      companyName: companyName,
      mobile: userMobile,
      city: userUbication,
      email: userEmail.value,
    };
    classesService
      .postNotEnableCompanyInscriptions(payload)
      .then(function (res) {
        Swal.fire({
          confirmButtonColor: '#6F119B',
          text: 'Gracias por tu interés, te contactaremos dentro de las proximas 24 horas.',
          icon: 'success',
          showConfirmButton: true,
          confirmButtonText: 'Entendido',
        }).then(function (res) {
          Swal.close();
          document.getElementById('topCloseButtonCompanyModal').click();
        });
        return res;
      })
      .catch(function (res) {
        Swal.fire({
          showConfirmButton: true,
          confirmButtonColor: '#6F119B',
          // cancelButtonColor: '#6F119B',
          title: 'Error!',
          text: 'Tuvimos un error con nuestros sistemas, puedes comunicarte a través de nuestras redes sociales para solicitar ayuda.',
          icon: 'error',
          confirmButtonText: 'Entendido',
        }).then(function (res) {
          Swal.close();
          document.getElementById('topCloseButtonCompanyModal').click();
        });
      });
  };

  const validEmailRegex = RegExp(
    /^((([^<>()[\].,;:\s@"]+(.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+.)+[^<>()[\].,;:\s@"]{2,})|)$/i
  );

  function onChangeEmail(e) {
    var _email = e.target.value;
    setUserEmail((prevState) => ({ ...prevState, value: _email }));

    if (validEmailRegex.test(_email)) {
      setUserEmail((prevState) => ({ ...prevState, valid: true }));
    } else {
      setUserEmail((prevState) => ({ ...prevState, valid: false }));
    }
    return true;
  }

  return (
    <div style={{ marginLeft: 0, padding: 0 }}>
      {/* <button
                type='button'
                className='detail-btn-inscription-class'
                data-bs-toggle='modal'
                data-bs-target='#InterestedCompanyModal'

            >
                Soy empresa
            </button> */}
      <div className='col mx-auto px-2'>
        <button
          type='button'
          data-bs-toggle='modal'
          data-bs-target='#staticBackdrop'
          className={`btn btn-sm ${styles.button_yellow}`}
        >
          Soy empresa
        </button>
      </div>
      <div
        className='modal fade'
        id='InterestedCompanyModal'
        data-bs-backdrop='static'
        data-bs-keyboard='false'
        // tabindex='-1'
        aria-labelledby='InterestedCompanyModalLabel'
        aria-hidden='true'
      >
        <div className='modal-dialog'>
          <div className='modal-content'>
            <div
              className='modal-header'
              style={{ backgroundColor: '#FEC700' }}
            >
              <div className='modal-title' id='InterestedCompanyModalLabel'>
                <h2 className='form_header_title'>
                  ¿Quieres llevar este taller a tu organización?
                </h2>
                <h4 className='form_header_subtitle float-start'>
                  Déjanos tus datos y te contactaremos una vez el taller se
                  encuentro disponible
                </h4>
              </div>
              <button
                id='topCloseButtonCompanyModal'
                type='button'
                className='btn-close'
                data-bs-dismiss='modal'
                aria-label='Close'
              ></button>
            </div>
            <div className='modal-body'>
              <form
                onSubmit={handleSubmit}
                id='registerToClassForm'
                className='needs-validation insc_modal_body'
              >
                <div className='mb-3'>
                  <label
                    htmlFor='inputCompanyName'
                    className='form-label insc_label_class float-start'
                  >
                    Nombre de la empresa
                  </label>
                  <input
                    type='text'
                    className='form-control insc_input'
                    id='inputcompanyName'
                    placeholder='Ej: Javier Montenegro'
                    onChange={(e) => setCompanyName(e.target.value)}
                    required
                  />
                </div>
                {/* Correo */}
                <div className='mb-3'>
                  <label
                    htmlFor='inputUserEmail'
                    className='form-label insc_label_class float-start'
                  >
                    Correo electrónico
                  </label>
                  <input
                    value={userEmail.value}
                    type='email'
                    className={`form-control insc_input ${
                      userEmail.valid === true
                        ? 'is-valid'
                        : userEmail.valid === false
                        ? 'is-invalid'
                        : ''
                    }`}
                    id='inputUserEmail'
                    placeholder='nombre@ejemplo.com'
                    onChange={onChangeEmail}
                    required
                  />
                  <div className='invalid-feedback'>Correo no válido.</div>
                </div>
                <div className='form-outline mb-3'>
                  <label
                    htmlFor='phone'
                    className='form-label insc_label_class float-start'
                  >
                    Celular
                  </label>
                  <input
                    type='number'
                    id='phone'
                    className='form-control insc_input'
                    data-mdb-input-mask='+48 999-999-999'
                    placeholder='Ej: +56912345678'
                    onChange={(e) => setUserMobile(e.target.value)}
                  />
                </div>
                {/* Ubicacion */}
                <div className='mb-3'>
                  <label
                    htmlFor='phone'
                    className='form-label insc_label_class float-start'
                  >
                    Ciudad
                  </label>
                  <input
                    type='inputCity'
                    className='form-control insc_input'
                    id='inputUserUbication'
                    placeholder='Ej: Santiago, Chile'
                    onChange={(e) => setUbication(e.target.value)}
                    required
                  />
                </div>

                {/* SUBMIT */}
                <div className='modal-footer'>
                  <button type='submit' className='btn insc_send_btn'>
                    Enviar
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default InterestedCompanyInClass;
