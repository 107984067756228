import React from 'react';
import styles from './About.module.css';
import { ReactComponent as Meteoritos } from './assets/meteoritos.svg';
// import { ReactComponent as LeftYellow } from './assets/left-yellow.svg';
// import { ReactComponent as RightYellow } from './assets/right-yellow.svg';
import lef_yellow from './assets/left-yellow.png';
import right_yellow from './assets/right-yellow.png';

function About() {
  return (
    <div className='container-fluid p-0'>
      <img
        className={styles.left_yellow}
        src={lef_yellow}
        alt='adorno izquierda'
      />
      <div
        className={` justify-content-end list-unstyled d-flex ${styles.meteoritos_container}`}
      >
        <Meteoritos className={styles.meteoritos} />
      </div>
      <div
        // style={{ backgroundColor: '#470467' }}
        className={`row justify-content-end  ${styles.slots}`}
      >
        <div className={`col col-xl-6 col-sm-10 col-lg-10 ${styles.proposito_root}`}>
          <h1 className={`${styles.proposito} ${styles.titulo}`}>
            Nuestro Propósito
          </h1>
          <p className={styles.descripcion1}>
            Queremos contribuir al desarrollo social, laboral, cultural y
            medioambiental de los individuos, potenciando la educación no formal
            y facilitando el encuentro entre ofertantes y demandantes. Esto
            disminuye las barreras de la transferencia de conocimiento.
          </p>
        </div>
      </div>
      <div style={{ backgroundColor: 'white' }}>
        <div className={`row justify-content-between ${styles.slots} `}>
          <div className='col col-sm-5 col-lg-5'>
            <div className={`${styles.valores_root}`}>
              <h1 className={`${styles.valores} ${styles.titulo}`}>
                Nuestra Misión
              </h1>
              <p className={styles.descripcion2}>
                Ser la herramienta que ayude a explorar nuevas formas de
                adaptarse en un mundo en constante cambio, a través de la
                educación no formal.
              </p>
            </div>
          </div>

          <div className={`col ${styles.container_right_yellow} `}>
            <div className={`col justify-content-end list-unstyled d-flex`}>
              <img
                className={styles.right_yellow}
                src={right_yellow}
                alt='asdasd'
              />
            </div>
          </div>

          {/* <div className={`col-1 ${styles.spacer_right}`} /> */}
        </div>
      </div>
    </div>
  );
}

export default About;
